<template>
  <div class="yedion-main">
    <div class="yedion-main-wrapper">
        <div class="yedion-main-wrapper-header">
            <h1>ידיעון</h1>
            <div @click="is_yedion=false; handle_clean_checks_boxes(); handle_back_to_shilut()" class="back-btn">
                <i class="material-icons back_icon">west</i>
            </div>
        </div>
        <div class="yedion-main-wrapper-tools">
            <el-button @click="handle_clean_checks_boxes" class="slide-in-right" v-if="selected_deals.length > 0" style="margin-right:5px;" type="danger">נקה בחירה</el-button>
            <el-button @click="handle_push_to_signs" class="slide-in-right" v-if="selected_deals.length > 0" style="margin-right:5px;" type="warning">הנפק שילוט</el-button>
            <el-input  style="width:20%; margin-right:5px;" v-model="search" placeholder="חיפוש" />
            <el-select style="margin-right:5px;" clearable  filterable v-model="selected_department" class="m-2" placeholder="מחלקה" size="large">
                <el-option
                v-for="cat in categories"
                :key="cat"
                :value="cat"
                />
            </el-select>
            <el-select style="margin-right:5px;" clearable  filterable v-model="selected_supplier" class="m-2" placeholder="ספק" size="large">
                <el-option
                v-for="sup in suppliers"
                :key="sup"
                :value="sup"
                />
            </el-select>
            <el-button @click="show_promos_list = true" style="margin:0 5px;" type="success">הוספה</el-button>
            <el-button v-if="selected_deals.length > 0" @click="handle_delete_marked_promos"  style="margin:0 5px;" type="danger">מחיקת מסומנים</el-button>
            <el-button @click="handle_export_to_excel"  type="success">ייצא לאקסל</el-button>
            <el-button @click="handle_delete_the_yedion_totally" style="margin-right:auto;"   type="danger">מחיקת הידיעון</el-button>
            
        </div>
        <div v-loading="is_loading" class="yedion-main-wrapper-content">
            <h1 style="text-align:center;" v-if="slice_list.length==0">לא קיימות רשומות</h1>
            <table v-else>
                <tr>
                    <th style="width:50px;"><input type="checkbox" v-model="selected_all_indication" :checked="is_selected_all"  @change="toggleAllDealSelection()"></th>
                    <th style="cursor:pointer;" @click="handleSortTable('קוד', !selectedSorted.order)">קוד מבצע</th>
                    <th style="cursor:pointer;" @click="handleSortTable('ספק', !selectedSorted.order)">שם ספק</th>
                    <th style="cursor:pointer;" @click="handleSortTable('שם', !selectedSorted.order)">שם מבצע</th>
                    <th>ברקוד מוצר</th>
                    <th style="cursor:pointer;" @click="handleSortTable('מחיר', !selectedSorted.order)">מחיר מבצע</th>
                    <th style="cursor:pointer;" @click="handleSortTable('כמות', !selectedSorted.order)">כמות</th>
                    <th>יחידות מידה</th>
                    <th>הגבלות</th>
                    <th style="cursor:pointer;" @click="handleSortTable('עד תאריך', !selectedSorted.order)">תוקף המבצע</th>
                    <th>מבצע</th>
                    <th style="cursor:pointer;" @click="handleSortTable('מחלקה', !selectedSorted.order)">מחלקה</th>
                    <th>פעולות</th>
                </tr>
                <template v-for="deal in slice_list" :key="deal.promoCode">
                    <tr>
                        <td>
                            <input type="checkbox" :checked="isDealSelected(deal)" :value="deal.promoCode" @change="toggleDealSelection(deal)">
                        </td>
                        <td>{{deal.promoCode}}</td>
                        <td>{{deal.supplier || 'לא צויין'}}</td>
                        <td>{{deal.RemarkForPrint}}</td>
                        <td v-if="deal.products.length>0">{{deal.products.map(product=>product.barcode).join(', ')}}</td>
                        <td v-else style="color:var(--danger)">לא קיימים מוצרים <br> נא לפנות למחלקת סחר</td>
                        <td>{{deal.sum_price}} &#8362;</td>
                        <td>{{Number(deal.amount)>=1?deal.amount:'1'}} </td>
                        <td>{{Number(deal.amount)>=1?'יח\'':'ק"ג'}} </td>
                        <td>
                            {{deal.limitations.filter(lim=> lim && lim.trim()).join(', ').length>0?deal.limitations.filter(lim=> lim && lim.trim()).join(', '):'אין הגבלות'}}
                        </td>
                        <td>
                            עד ה - {{new Date(deal.ToDate).toLocaleDateString('he')}}
                        </td>
                        <td>
                            {{Number(deal.amount)>=1?`${deal.amount} יח' ב - ${deal.sum_price}`:`1 ק"ג ב - ${deal.sum_price}`}}
                        </td>
                        <td>
                            {{deal.products.length>0?deal.products[0].Department:'לא צויין'}}
                        </td>
                        <td>
                            <i @click="handle_delete_deal(deal)" class="material-icons delete_icon">delete</i>
                        </td>
                    </tr>
                </template>
            </table>
        </div>
        <div class="shilut-deals-db-pagination">
            <el-pagination 
                v-model:currentPage="pagination.current_page"
                background 
                :page-size="pagination.number_per_page"
                layout="prev,pager,next"
                :total="sorted_list.length"
            />
        </div>
    </div>
    <PromosionList @insert_submit="handle_clean_checks_boxes(); show_promos_list=false;" @close="show_promos_list=false" v-if="show_promos_list"/>
  </div>
</template>

<script>
import { computed, defineAsyncComponent, ref } from 'vue'
import router from '../../../../../router'
import {items,categories,suppliers} from '../../components/Deals/Scripts/script'
import {init_yedion_promos,delete_promo_from_db,delete_marked_promos,delete_the_yedion_totally} from './Script/script'
import {yedion_promos,is_yedion,selected_all_indication,selected_yedion_promos} from './store'
import {selected_deals,is_deleted,printer_amount,is_catalog_signs} from '../Deals/Scripts/store'
import {alert_confirm,slide_pop_error} from '../../../../../Methods/Msgs'
import XLSX from "xlsx";
export default {
    components:{
        PromosionList:defineAsyncComponent(() => import('./PromosionList.vue'))
    },
    setup(){

        const is_selected_all = ref(computed(()=>{
            const set = new Set()
            slice_list.value.forEach(deal => {
                if(!set.has(deal.promoCode)) set.add(deal.promoCode)
            })

            const all_signs = selected_deals.value.filter(deal => set.has(deal.promoCode)).length
            if (all_signs == slice_list.value.length) return true
            return false
        }))
        
        const isDealSelected = (deal) => {
            return selected_deals.value.some(i => i.promoCode === deal.promoCode);
        };

        const toggleDealSelection = (deal) => {
            const index = selected_deals.value.findIndex(i => i.promoCode === deal.promoCode);
            if(index !== -1){
                selected_deals.value = selected_deals.value.filter(({promoCode}) => promoCode != deal.promoCode );

            }else{
                selected_deals.value.push(deal);
            }
        }
        const toggleAllDealSelection = () => {
            if(selected_all_indication.value){
                selected_deals.value = [...selected_deals.value,...slice_list.value]
            }else{
                const set = new Set()
                slice_list.value.forEach(deal => {
                    if(!set.has(deal.promoCode)) set.add(deal.promoCode)
                })
                selected_deals.value = selected_deals.value.filter(deal => !set.has(deal.promoCode))
                selected_all_indication.value = false
            }
        }
        const is_loading = ref(false)
        const show_promos_list = ref(false)
        const search = ref('')
        const selected_department = ref('')
        const selected_supplier = ref('')

        const pagination = ref({
            current_page:1,
            number_per_page:50,
        })
        
        const selectedSorted = ref({
            name: "",
            order: false
        });

        const handle_delete_the_yedion_totally = async() => {
            try{
                alert_confirm('למחוק את הידיעון?','error','!')
                .then(async res => {
                    if(res.isConfirmed){
                        is_loading.value = true
                        await delete_the_yedion_totally()
                        yedion_promos.value = []
                        is_loading.value = false
                    }
                })
            }catch(err){
                is_loading.value = false
                slide_pop_error(err.message)
            }
        }

        const handle_push_to_signs = () => {
            is_catalog_signs.value = false
            router.push({name:'Shilut'})
        }

        const handle_delete_marked_promos = () => {
            try{
                alert_confirm('האם למחוק את כל המבצעים שסומנו?')
                .then(async res => {
                    if(res.isConfirmed){
                       is_loading.value = true
                       await delete_marked_promos()
                       handle_clean_checks_boxes()
                       is_loading.value = false
                    }
                })
            }catch(err){
                slide_pop_error(err.message)
            }
        }

        const handle_clean_checks_boxes = () => {
            selected_deals.value = []
            printer_amount.value = []
            selected_yedion_promos.value = []
            selected_all_indication.value = false
            is_deleted.value = false
        }

        const handleSortTable = (name, order) => {
            selectedSorted.value.name = name;
            selectedSorted.value.order = order;
        };
        
        const slice_list = ref(computed(()=>{
            return sorted_list.value.slice((pagination.value.current_page -1 ) * pagination.value.number_per_page,
            ((pagination.value.current_page-1) * pagination.value.number_per_page) + pagination.value.number_per_page)
        }))

        const sorted_list = ref(computed(() => {
            if (selectedSorted.value.name == "")return filter_by_supplier.value;
            if (selectedSorted.value.name == "קוד") {
                return filter_by_supplier.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  Number(a.promoCode) > Number(b.promoCode)
                        ? 1
                        : Number(b.promoCode)  > Number(a.promoCode)
                        ? -1
                        : 0;
                    }
                    return Number(a.promoCode) > Number(b.promoCode)
                    ? -1
                    : Number(b.promoCode)  > Number(a.promoCode)
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "ספק") {
                return filter_by_supplier.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a?.supplier > b?.supplier
                        ? 1
                        : b?.supplier  > a?.supplier
                        ? -1
                        : 0;
                    }
                    return a?.supplier > b?.supplier
                    ? -1
                    : b?.supplier  > a?.supplier
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "שם") {
                return filter_by_supplier.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  a?.RemarkForPrint > b?.RemarkForPrint
                        ? 1
                        : b?.RemarkForPrint  > a?.RemarkForPrint
                        ? -1
                        : 0;
                    }
                    return a?.RemarkForPrint > b?.RemarkForPrint
                    ? -1
                    : b?.RemarkForPrint  > a?.RemarkForPrint
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "מחיר") {
                return filter_by_supplier.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  Number(a?.sum_price) > Number(b?.sum_price)
                        ? 1
                        : Number(b?.sum_price)  > Number(a?.sum_price)
                        ? -1
                        : 0;
                    }
                    return Number(a?.FromDate) > Number(b?.FromDate)
                    ? -1
                    : Number(b?.FromDate)  > Number(a?.FromDate)
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "כמות") {
                return filter_by_supplier.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  Number(a?.amount) > Number(b?.amount)
                        ? 1
                        : Number(b?.amount)  > Number(a?.amount)
                        ? -1
                        : 0;
                    }
                    return Number(a?.amount) > Number(b?.amount)
                    ? -1
                    : Number(b?.amount)  > Number(a?.amount)
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "עד תאריך") {
                return filter_by_supplier.value.sort((a, b) => {
                    if (selectedSorted.value.order) {
                    return  new Date(a?.ToDate) > new Date(b?.ToDate)
                        ? 1
                        : new Date(b?.ToDate)  > new Date(a?.ToDate)
                        ? -1
                        : 0;
                    }
                    return new Date(a?.ToDate) > new Date(b?.ToDate)
                    ? -1
                    : new Date(b?.ToDate)  > new Date(a?.ToDate)
                    ? 1
                    : 0;
                });
            }
            if (selectedSorted.value.name == "מחלקה") {
                return filter_by_supplier.value.sort((a, b) => {
                    const aProducts = a.products || [];
                    const bProducts = b.products || [];
                    if (aProducts.length === 0 && bProducts.length === 0) {
                        return 0;
                    } else if (aProducts.length === 0) {
                        return 1;
                    } else if (bProducts.length === 0) {
                        return -1;
                    } else {
                        if (selectedSorted.value.order) {
                            return (aProducts[0].Department > bProducts[0].Department)
                                ? 1
                                : bProducts[0].Department > aProducts[0].Department
                                    ? -1
                                    : 0;
                        }
                        return (aProducts[0].Department > bProducts[0].Department)
                            ? -1
                            : bProducts[0].Department > aProducts[0].Department
                                ? 1
                                : 0;
                    }
                    });
            }
        }));

        const filter_by_supplier = ref(computed(() => {
            if(!selected_supplier.value || selected_supplier.value == 'הכל') return filter_by_department.value
            return filter_by_department.value.filter(deal => deal.products.length > 0 && deal.products[0].SupplierName == selected_supplier.value)
        }))
        
        const filter_by_department = ref(computed(() => {
            if(!selected_department.value || selected_department.value == 'הכל') return filter_by_search.value
            return filter_by_search.value.filter(deal => deal.products.length > 0 && deal.products[0].Department == selected_department.value)
        }))

        const filter_by_search = ref(computed(()=>{
            if(!search.value) return yedion_promos.value
            return yedion_promos.value.filter(deal=>{
                if(deal.products.length > 0 && deal.products.map((product)=>product.barcode).join().includes(search.value)) return deal
                if(deal.promoCode?.includes(search.value)) return deal
                if(deal.supplier?.includes(search.value)) return deal
                if(deal.RemarkForPrint?.includes(search.value)) return deal
                if(deal.Stores?.includes(search.value)) return deal
                if(new Date(deal.FromDate).toLocaleDateString('he').includes(search.value)) return deal
                if(new Date(deal.ToDate).toLocaleDateString('he').includes(search.value)) return deal
            })
        }))

        const handle_back_to_shilut = () => {
            router.push({name:'ShilutDealsManage'})
        }

        const handle_delete_deal = (deal) => {
            try{
                console.log(deal);
                alert_confirm(`להסיר את המבצע, ${deal.RemarkForPrint}?`)
                .then(async res => {
                    if(res.isConfirmed){
                        is_loading.value = true
                        await delete_promo_from_db(deal)
                        is_loading.value = false
                    }
                })
            }catch(err){
                slide_pop_error(err.message)
            }
            
        }

        const handle_export_to_excel = ()=>{
            console.log(yedion_promos.value);
            let rows = JSON.parse(JSON.stringify(yedion_promos.value))
            let workbook = XLSX.utils.book_new();
            let workSheetData =[]
            workSheetData.push(['קוד מבצע','שם ספק','שם מבצע','ברקוד מוצר','מחיר מבצע','כמות','יחידות מידה','הגבלות','תוקף מבצע','מבצע','מחלקות'])
            rows.forEach(row=>{
                workSheetData.push(
                    [
                        row.promoCode,
                        row.supplier || 'לא צויין',
                        row.RemarkForPrint,
                        row.products.length > 0?row.products.map(product=>product.barcode).join(', '):'נא לפנות למחתקת סחר לא קיימים מוצרים',
                        row.sum_price+' ₪',
                        Number(row.amount) >= 1?row.amount:'1',
                        Number(row.amount) >= 1?'יח\'':'ק"ג',
                        row.limitations.filter(lim=> lim && lim.trim()).join(', ').length>0?row.limitations.filter(lim=> lim && lim.trim()).join(', '):'אין הגבלות',
                        new Date(row.ToDate).toLocaleDateString('he'),
                        Number(row.amount)>=1?`${row.amount} יח' ב - ${row.sum_price}`:`1 ק"ג ב - ${row.sum_price}`,
                        row.products.length>0?row.products[0].Department:'לא צויין',
                    ]
                )
            })
            const workSheet = XLSX.utils.aoa_to_sheet(workSheetData)
            workbook.Workbook = {};
            workbook.Workbook.Views = [];
            workbook.Workbook.Views[0] = {};
            workbook.Workbook.Views[0].RTL = true;
            XLSX.utils.book_append_sheet(workbook, workSheet, "רשימת ידיעון");
            XLSX.writeFile(workbook, "ידיעון.xlsx");
        }

        const init = async()=>{
            is_yedion.value = true
            is_loading.value = true
            await init_yedion_promos()
            is_loading.value = false
        }

        init()

        return{
            selected_all_indication,
            handle_back_to_shilut,
            handleSortTable,
            handle_delete_deal,
            handle_push_to_signs,
            toggleAllDealSelection,
            init,
            search,
            selected_department,
            selected_supplier,
            items,
            categories,
            suppliers,
            show_promos_list,
            is_loading,
            pagination,
            filter_by_search,
            sorted_list,
            selectedSorted,
            filter_by_department,
            filter_by_supplier,
            slice_list,
            is_yedion,
            isDealSelected,
            toggleDealSelection,
            selected_deals,
            handle_clean_checks_boxes,
            is_selected_all,
            handle_delete_marked_promos,
            handle_export_to_excel,
            handle_delete_the_yedion_totally
        }
    }
}
</script>

<style scoped>
    .yedion-main{
        width: 100%;
        height: 100%;
        color: #fff;
    }
    .yedion-main-wrapper{
        width: 100%;
        height: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 5px 0;
    }
    .yedion-main-wrapper-header{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--secondary);
        border-radius: 10px;
    }
    .yedion-main-wrapper-tools{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 5px;
    }
    .yedion-main-wrapper-content{
        width: 100%;
        height: calc(100% - 150px);
        overflow-y: auto;
    }
    .shilut-deals-db-pagination{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .delete_icon{
        cursor: pointer;
        color: var(--danger);
        user-select: none;
    }

    ::v-deep(.el-pagination.is-background .btn-next i){
        transform: rotate(180deg);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    ::v-deep(.el-pagination.is-background .btn-prev i){
        transform: rotate(180deg);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    ::v-deep(.el-pagination.is-background .el-pager li:not(.disabled).active){
        background: var(--success);
        color: #fff;
        
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: lightseagreen !important;
        color: white;
        position: sticky;
        top: 0;
        font-size: 20px;
    }
    @media only screen and (max-width: 600px){
        table th {
            position: static;
        }
    }

    
    .back-btn{
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        background: var(--danger);
        cursor: pointer;
    }
    .back_icon{
        color: #fff;
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--purple);
        color: white;
        position: sticky;
        top: 0;
        font-size: 20px;
    }
    @media only screen and (max-width: 600px){
        table th {
            position: static;
        }
    }

    .slide-in-right {
	    -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	    animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @-webkit-keyframes slide-in-right {
        0% {
            -webkit-transform: translateX(1000px);
                    transform: translateX(1000px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(0);
                    transform: translateX(0);
            opacity: 1;
        }
    }
    @keyframes slide-in-right {
        0% {
            -webkit-transform: translateX(1000px);
                    transform: translateX(1000px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(0);
                    transform: translateX(0);
            opacity: 1;
        }
    }
</style>